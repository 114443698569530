import Oxide from './Oxide';
import abi from './abi';

const requiredAllowance = '500';

export {
  Oxide,
  abi,
  requiredAllowance,
};
