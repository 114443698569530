import Status from './Status';
import abi from './abi';

const requiredAllowance = '1';

export {
  Status,
  abi,
  requiredAllowance,
};
